// JS Cookie
import cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";
import GetTenant from "./GetTenant";
import * as Sentry from "@sentry/nextjs";
import { GetEnvironment } from "./GetEnvironment";

export default function logout(locale?: string) {
  console.log("user cookie logging out", cookie.get("currentUser"));
  if (typeof window !== "undefined" && window.self === window.top) {
    var querystring: string =
      "?returnUrl=[" +
      `${window.location.origin}${window.location.pathname}` +
      "]&returnQuery=[" +
      encodeURIComponent(window.location.search) +
      "]";

    cookie.remove("currentUser");
    localStorage.removeItem("usercode");
    localStorage.removeItem("roles");
    localStorage.removeItem("kvs");
    localStorage.removeItem("actualInvoiceId");
    localStorage.removeItem("tokenExpiration");
    redirectToLogin(locale || "fr", querystring);
  }
}

// Function to decode JWT and set auto-logout
export function setAutoLogout(token: string) {
  const locale = getLocale(window.location.pathname);
  if (typeof window === "undefined") {
    return;
  }

  console.log("setAutoLogout", token);
  try {
    const expiration = jwtDecode(token);
    console.log("expiration", expiration);

    // Get current time and token expiration time
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = expiration.exp || currentTime; // current time if expiration date is undefined
    const timeUntilExpiration = (expirationTime - currentTime) * 1000; // in milliseconds

    console.log("timeUntilExpiration", timeUntilExpiration);
    if (timeUntilExpiration > 0) {
      localStorage.setItem("tokenExpiration", expirationTime.toString());
      // Set timeout to auto-logout the user
      setTimeout(() => logout(locale), timeUntilExpiration);
    } else if (window.self === window.top) {
      // Token is already expired
      logout(locale);
    }
  } catch (error) {
    console.error("Invalid token", error);
    Sentry.captureException(error);
  }
}

export function redirectToLogin(locale: string, redirectLink?: string) {
  const pageNoRedirect = ["support", "status"];
  const tenant = GetTenant();
  const environment =
    GetEnvironment(window.location.href) === "prod"
      ? "."
      : `.${GetEnvironment(window.location.href)}.`;
  console.log("tenant", tenant);

  let shouldRedirect = true;
  pageNoRedirect.forEach((page) => {
    if (window.location.href.includes(page)) {
      shouldRedirect = false;
    }
  });
  if (shouldRedirect) {
    window.location.href = `https://${tenant}${environment}login.phpr.link/${locale}${redirectLink}`;
  }
}

export function getLocale(url: string) {
  const locale = url.split("/")[1];
  return locale;
}
